.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo:hover {
  transform: scale(1.1);
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #222222;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary-text {
  color: #fc6803;
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

h1 {
  font-family: "trade-gothic-next-condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.round-dot {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fc6803;
}

.button {
  background-color: #fc6803;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: .6em; 
  font-style: normal;
  transition: all 0.3s ease;
  text-decoration: none;
}

.button:hover {
  background-color: #a4a4a4;
  color: #000;
  transition: all 0.3s ease;
}

input {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-style: normal;
  transition: all 0.3s ease;
  width: 150px;
  margin-right: 10px;
  text-decoration: none;
}